<template>
  <div id="app">
    <h3>
      $0.01 is equal to
      <img src="./assets/sat_black.svg" height="20" alt="" />{{
        satoshisPerPenny
      }}
    </h3>

    <div>Data kindly provided by <a href=" messari.io"> messari.io</a></div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  computed: {
    satoshisPerPenny: function () {
      if (!this.bitcoinPrice) {
        return "Loading...";
      }
      return Number.parseFloat(this.bitcoinPrice / 100000000).toFixed(5);
    },
  },
  created() {
    fetch("https://data.messari.io/api/v1/assets/bitcoin/metrics")
      .then((response) => response.json())
      .then((data) => {
        this.bitcoinPrice = data.data.market_data.price_usd;
      });
  },
  data() {
    return {
      bitcoinPrice: undefined,
    };
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
h1,
p {
  margin: 0 0 1em;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
  overflow-y: hidden;
}
</style>
